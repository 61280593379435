
#team .team-image > div {
    position:relative;
}
#team .team-image > div > img {
    transition: opacity 0.5s;
}
#team .team-image > div > img:nth-child(2) {
    position:absolute;
    top:0px;
    left:0px;
}
#team .team-image:hover > div > img:nth-child(2) {
    opacity: 0;
}


